import * as React from 'react';
import { ArrowLeft } from '@kota/icons';
import { Button } from '@kota/ui';
import { Link, useLocation } from '@remix-run/react';
import type { LinksFunction } from '@vercel/remix';
import Marquee from 'react-fast-marquee';
import { $path } from 'remix-routes';

import { KotaSymbolAndWordmarkLogo } from '@yonderlabs-packages/brand-assets';

import { LogoutFormButton } from '~/src/features/logout/forms';
import { onboardMarketingResolver } from './helpers';

type Props = {
  isLoggedIn?: boolean;
  children: React.ReactNode;
};

export function OnboardingLayout({ children, isLoggedIn }: Props) {
  const { pathname } = useLocation();
  const isEmployeeOnboarding = pathname === $path('/onboarding/employee');

  return (
    <div className='mx-auto grid max-w-screen-2xl md:grid-cols-2'>
      <div className='md:py-30 flex min-h-screen flex-col justify-between p-10 md:px-20 xl:px-32'>
        <div className='flex h-12 items-center justify-between'>
          <Link prefetch='intent' to={isLoggedIn ? $path('/') : $path('/login')}>
            <KotaSymbolAndWordmarkLogo className='h-7' />
          </Link>

          {isLoggedIn && <LogoutFormButton />}

          {isEmployeeOnboarding && (
            <Link prefetch='intent' to={$path('/onboarding')}>
              <Button variant='outline' size='icon'>
                <ArrowLeft className='h-5 w-5' />
              </Button>
            </Link>
          )}
        </div>

        <div className='space-y-8'>{children}</div>
        {/* ⚠️ DO NOT REMOVE THIS EMPTY DIV */}
        {/* This is necessary to center align `children` */}
        <div />
      </div>

      <div className='hidden md:block'>
        <RightSide />
      </div>
    </div>
  );
}

function RightSide(): JSX.Element {
  const { pathname } = useLocation();
  const { url } = onboardMarketingResolver(pathname);

  if (pathname === $path('/onboarding/register')) {
    return (
      <div className='p-6 md:h-screen'>
        <div
          className='flex h-full w-full flex-col justify-between overflow-hidden rounded-xl bg-primary/5 p-6'
          style={{
            backgroundSize: '100% auto',
            backgroundImage: 'url(/images/register-blobs-bg.png)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className='flex space-x-3 rounded-xl bg-white p-4'>
            <div>
              <img src='/images/register-testemony-avatar.png' alt='Jennifer Breathnach' className='h-14 w-14' />
            </div>

            <div className='flex-1 space-y-4'>
              <div>
                <div className='font-medium'>Jennifer Breathnach</div>
                <div className='text-sm text-muted-foreground'>People Manager at Dogpatch Labs</div>
              </div>

              <div className='italic'>
                Everyone has been delighted with Kota. Really happy about it. I think the fact that it’s so easy to use
                has brought a lot of happiness. If only everything was as easy.
              </div>
            </div>
          </div>

          <div className='flex grow items-center'>
            <img
              src='/images/register-app-screenshot.png'
              alt='Kota Benefits'
              className='mx-auto max-h-[80%] w-auto rounded-xl shadow-xl ring-4 ring-black'
            />
          </div>

          <ClientsMarquee />
        </div>
      </div>
    );
  }

  if (pathname === $path('/login')) {
    return (
      <div className='p-6 md:h-screen'>
        <div
          className='flex h-full w-full flex-col justify-between overflow-hidden rounded-xl bg-primary/5 p-6'
          style={{
            backgroundSize: '90% auto',
            backgroundImage: 'url(/images/login-blobs-bg.png)',
            backgroundPosition: 'right center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div>
            <div className='font-faktum text-4xl'>
              Benefits your team loves, <br /> without the admin you hate
            </div>
          </div>

          <div className='flex max-h-[70%] items-center justify-center'>
            <img src='/images/login-app-screenshot.png' alt='Kota Benefits' className='h-full w-auto' />
          </div>

          <ClientsMarquee />
        </div>
      </div>
    );
  }

  if (pathname === $path('/onboarding') || pathname === $path('/onboarding/employee')) {
    return (
      <div className='p-6 md:h-screen'>
        <div
          className='flex h-full w-full flex-col justify-between rounded-xl bg-primary/5 p-6 pt-0'
          style={{
            backgroundSize: '100%',
            backgroundImage: 'url(/images/onboarding-blobs-bg.png)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className='max-h-[90%]'>
            <img src='/images/onboarding-app-screenshot.png' alt='Kota Benefits' className='mx-auto max-h-full' />
          </div>

          <ClientsMarquee />
        </div>
      </div>
    );
  }

  if (pathname === $path('/onboarding/profile')) {
    return (
      <div className='p-6 md:h-screen'>
        <div
          className='flex h-full w-full flex-col justify-center rounded-xl bg-[#FEF8F3] p-6 pt-0'
          style={{
            backgroundSize: 'cover',
            backgroundImage: 'url(/images/profile-blobs-bg.png)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className='space-y-12 px-12'>
            <div className='text-center font-faktum text-3xl'>Modern HR & finance teams run their benefits on Kota</div>

            <div className='space-y-4'>
              <div className='text-center font-medium'>Trusted by amazing teams</div>

              <div className='flex flex-wrap items-center justify-center gap-8'>
                {CLIENTS_LOGOS.map((logo) => {
                  return <img key={logo} src={logo} alt='Client Logo' className='h-8' />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (pathname === $path('/forgot-password') || pathname === $path('/reset-password')) {
    return (
      <div className='p-6 md:h-screen'>
        <div
          className='flex h-full w-full flex-col items-end justify-end rounded-xl bg-[#f6f6f6]'
          style={{
            backgroundSize: '100% auto',
            backgroundImage: 'url(/images/forgot-password-blobs-bg.png)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className='max-w-[85%]'>
            <img src='/images/forgot-password-app-screenshot.png' alt='Kota Benefits' />
          </div>
        </div>
      </div>
    );
  }

  if (pathname === $path('/onboarding/email-verification')) {
    return (
      <div className='p-6 md:h-screen'>
        <div
          className='flex h-full w-full flex-col items-center justify-center rounded-xl bg-[#f6f6f6]'
          style={{
            backgroundSize: 'auto 100%',
            backgroundImage: 'url(/images/email-verification-blobs-bg.png)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div>
            <img src='/images/email-verification-gmail-icon.png' alt='Gmail Icon' className='h-36' />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='invisible flex flex-col items-center justify-center gap-12 bg-yonder-main px-10 md:visible md:min-h-full'>
      <img className='max-h-[672px] max-w-2xl pt-14' src={`/images/app/onboarding/${url}`} alt='kota-sign-in' />
    </div>
  );
}

function ClientsMarquee(): JSX.Element {
  return (
    <div className='-mx-6 flex flex-col items-center space-y-4'>
      <div className='font-medium'>Trusted by amazing teams</div>

      <Marquee gradient gradientColor='#F7F3FE' speed={50} className='h-8 overflow-hidden'>
        <div className='flex items-center'>
          {CLIENTS_LOGOS.map((logo) => {
            return <img key={logo} src={logo} alt='Client Logo' className='mx-4 h-8' />;
          })}
        </div>
      </Marquee>
    </div>
  );
}

const CLIENTS_LOGOS = [
  '/images/greyscout.png',
  '/images/dogpatch-labs.png',
  '/images/fonoa.png',
  '/images/spotlight-oral-care.png',
  '/images/nory.png',
  '/images/coffeeangel.png',
  '/images/unmind.png',
];

export const links: LinksFunction = () => {
  const images = [...CLIENTS_LOGOS];

  return images.map((image) => ({ rel: 'prefetch', href: image, as: 'image' }));
};
